import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 62.5%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    background: var(--black_background);
    line-height: 1;
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }
  p {
    line-height: 28px;
  }

  .react-share__ShareButton {
    margin: 1rem 1rem 0 0;
  }
  .react-share__ShareButton svg:hover circle {
    fill: var(--orange) !important;
  }



  /* Variables */
    body {
        --white: #f0f0f1;
        --black_background: #08070e;
        --black_background_contrast: #181d24;
        --grey_back: #212529;
        --grey: #2a2e32;
        --grey_ligh: #979ba0;
        --orange: #FA4E0A;
        --dark_orange: #D65C04;
        --light_orange: #ED8A11;
        --yellow: #FABB05;
        --red: #db0000;
    }
`
export default GlobalStyles
